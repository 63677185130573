.btn-success {
  color: #fff;
  background-color: #71dd37;
  border-color: #71dd37;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(113, 221, 55, 0.4);
  margin: 5px;
}
.menu-link:hover {
  background-color: darkgrey !important;
}
