#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: -20px;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 4px;
  margin: 3px;
}
.bth {
  padding-top: 3px;
  margin-top: -4px;
}
/* #8592a3 */
.handleLabels {
  color: white;
  border-radius: 50%;
  margin: 5px;
  border: 2px solid #337ab7;
  width: 32px;
  padding: 2px;
  background-color: #337ab7;
  padding-right: 6px;
  padding-top: 3px;
}
.handleLabels1 {
  color: white;
  border-radius: 50%;
  margin: 5px;
  border: 2px solid #8592a3;
  width: 34px;
  padding: 3px;
  background-color: #8592a3;
  padding-right: 2px;
  padding-top: 4px;
}
.handleUploadImg {
  height: 5.5rem;
  width: 6rem;
  margin-left: -90.5%;
  /* margin-top: 0.2%; */
}
.bth:hover {
  padding-top: 3px;
  background-color: #337ab7;
  color: #fff !important;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}
.handleBox {
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem 0.5rem 0 0;
  border: 1px solid #d9dee3;
  margin-top: 16px;
  padding: 5px;
}
.handleForm {
  width: 52%;
  margin-left: 47%;
  margin-top: -7%;
  border-radius: 0.5rem !important;
}
@media (max-width: 768px) {
  .handleForm {
    width: 80%; /* Adjust the width for smaller screens */
    margin-left: auto; /* Center the form horizontally */
    margin-right: auto;
    margin-top: 10px; /* Adjust the top margin */
  }
}

/* Media query for even smaller screens */
@media (max-width: 480px) {
  .handleForm {
    width: 95%; /* Adjust the width for tiny screens */
  }
}
@media (max-width: 1380px) {
  .handleForm {
    width: 52%;
    margin-left: 47%;
    margin-top: -5%;
    border-radius: 17px;
  }
}
/* Default styles */
/* Default styles for all devices */
.handleInputRadio {
  display: inline-flex;
  margin-top: -10%;
}

/* Responsive styles for screens smaller than 768px (Mobile devices) */
@media (max-width: 767px) {
  .handleInputRadio {
    display: block; /* Change to a block-level element on mobile screens */
    margin-top: 0; /* Remove the negative margin on mobile screens */
  }
}
@media (max-width: 767px) {
  .handleInputRadio {
    display: block; /* Change to a block-level element on mobile screens */
    margin-top: 0; /* Remove the negative margin on mobile screens */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .handleInputRadio {
  }
}

/* Responsive styles for screens larger than 1024px (Laptop devices and above) */
@media (min-width: 1025px) {
  .handleInputRadio {
    /* Adjust styles as needed for laptop devices and above */
  }
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 5px;
  padding-bottom: 6px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
.handleAddCatgory {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: inherit;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: #135aa7 !important;
}
.handleAddCatgory th {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: inherit;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: #135aa7 !important;
}
@media (max-width: 768px) {
  .handleAddCategory {
    font-size: medium;
  }
}

/* Responsive styles for screens with a maximum width of 480 pixels */
@media (max-width: 480px) {
  .handleAddCategory {
    font-size: small;
  }
}
.hdlMargin {
  margin-top: 10px;
}
.btn-top,
.btn6 {
  padding: 5px 0px !important;
  font-size: 20px;
  font-weight: 1000;
  background-color: grey;
  color: white;
  border-radius: 5px;

  /* border-radius: 20px; */
}
.btn6:hover {
  background-color: white;
  color: #ec681f;
}
.btn-top:hover {
  background-color: white;
  color: #ec681f;
}
.btn6 {
  margin-left: 100px;

  padding: 10px !important;
}

/* Mobile styles */
@media only screen and (max-width: 767px) {
  .form-group.col-md-6 {
    float: none;
    width: 100%;
  }
  .form-group.col-md-4 {
    float: none;
    width: 100%;
  }
  .form-group {
    margin-right: 0;
  }
  .form-group label {
    margin-right: 0;
  }
}
.saLabl {
  position: relative;
  /* top: 0.3rem; */
  left: -41.5%;
}
.mosum {
  position: relative;
  top: 0rem;
  left: -1.5%;
 
}
#inputEmail4 {
  background-color: #fff !important;
  width: 96%;
}
.handleradio {
  position: absolute;
  right: 31.5%;
}
@media only screen and(min-width:400px) and (max-width: 637px) {
  .handleradio {
    display: inline-block;
    margin-top: 10px;
    margin-left: -14.2rem;
  }
}

@media only screen and (max-width: 400px) {
  .handleradio {
    display: inline-block;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 638px) and (max-width: 1640px) {
  .handleradio {
    float: right !important;
    margin-top: 2px;
    /* margin-right: -3.3%;

    display: inline-flex !important; */
  }
  .hdlerado {
    display: inline-flex !important;
  }
}

/* @media only screen and (min-width: 1641px) {
  .handleradio {
  }
} */

/* 
style={{
                 
                }}
*/

/* Tablet styles */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .form-group.col-md-6 {
    float: left;
    width: 50%;
  }
  .form-group.col-md-4 {
    float: right;
    width: 50%;
  }
}

/* Laptop styles */
@media only screen and (min-width: 992px) {
  .form-group.col-md-6 {
    float: left;
    width: 50%;
  }
  .form-group.col-md-4 {
    float: right;
    width: 50%;
  }
}
