/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.table-responsive.addproduct {
  height: calc(100vh - 180px);
}

.table-responsive.addproduct thead {
  position: sticky;
  background: #fff;
  top: 0;
}
.th {
  background-color: #008e00;
}

.table-responsive::-webkit-scrollbar {
  width: 1em;
  height: 12px;
}

.table-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #135aa7;
  outline: 1px solid #135aa7;
  border-radius: 5px;
}
#rentors-plus {
  font-size: 20px;
}

.spinnerWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.433);
  z-index: 9999999;
  transition: all 0.2s ease-in-out;
}

.spinnerWrapper .spinner-border {
  z-index: 9999999999;
  transition: all 0.2s ease-in-out;
}

.customStyles {
  background-color: "#eeeeee";
  padding: "0 0.7em";
}

.expand-sidebar.layout-menu {
  transition: all 0.3s ease;
  transform: none !important;
  position: absolute !important;
  height: 100%;
}

.expand-sidebar.layout-menu .menu-inner {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
}

#layout-menu-close {
  cursor: pointer;
  position: relative;
  display: inline-block;
  z-index: 999;
  transform: translateX(50%);
}

#layout-menu-close i {
  color: #ffffff;
  font-size: 30px !important;
  background-color: rgb(8, 65, 112);
}

#layout-menu-close::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #135aa7;
  z-index: -4;
  border-radius: 50px;
}
html {
  font-family: sans-serif;
  font-size: 16px;
  /* padding: 2em; */
}

.input-switch {
  display: none;
}

.label-switch {
  display: inline-block;
  position: relative;
}

.label-switch::before,
.label-switch::after {
  content: "";
  display: inline-block;
  cursor: pointer;
  transition: all 0.5s;
}

.label-switch::before {
  width: 3em;
  height: 1em;
  border: 1px solid #757575;
  border-radius: 4em;
  background: #888888;
}

.label-switch::after {
  position: absolute;
  left: 0;
  top: -20%;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid #757575;
  border-radius: 4em;
  background: #ffffff;
}

.input-switch:checked ~ .label-switch::before {
  background: #00a900;
  border-color: #008e00;
}

.input-switch:checked ~ .label-switch::after {
  left: unset;
  right: 0;
  background: #00ce00;
  border-color: #009a00;
}

.info-text {
  display: inline-block;
}
/* CSS for the toggle switch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 20px;
  transition: 0.4s;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.toggle-switch input:checked + .toggle-slider {
  background-color: #66bb6a; /* Active color */
}

.toggle-switch input:checked + .toggle-slider:before {
  transform: translateX(20px);
}

.toggle-slider:before {
  transform: translateX(0px);
}

.nav-link[data-toggle].collapsed:after {
  content: " ▾";
}
.nav-link[data-toggle]:not(.collapsed):after {
  content: " ▴";
}

html {
  font-family: sans-serif;
  font-size: 16px;
  /* padding: 2em; */
}

.input-switch {
  display: none;
}

.label-switch {
  display: inline-block;
  position: relative;
}

.label-switch::before,
.label-switch::after {
  content: "";
  display: inline-block;
  cursor: pointer;
  transition: all 0.5s;
}

.label-switch::before {
  width: 3em;
  height: 1em;
  border: 1px solid #757575;
  border-radius: 4em;
  background: #888888;
}

.label-switch::after {
  position: absolute;
  left: 0;
  top: -20%;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid #757575;
  border-radius: 4em;
  background: #ffffff;
}

.input-switch:checked ~ .label-switch::before {
  background: #00a900;
  border-color: #008e00;
}

.input-switch:checked ~ .label-switch::after {
  left: unset;
  right: 0;
  background: #00ce00;
  border-color: #009a00;
}

.info-text {
  display: inline-block;
}

.info-text::before {
  content: "Not active";
}

.input-switch:checked ~ .info-text::before {
  content: "Active";
}

.info-text::before {
  content: "Not active";
}

.input-switch:checked ~ .info-text::before {
  content: "Active";
}

#layout-menu {
  transition: all 0.3s ease !important;
}

.toast.bs-toast {
  z-index: 99999999999999;
}
.handleBtn {
  background-color: #337ab7;
  color: #fff;
  border: 1px solid #337ab7;
  text-transform: capitalize;
  margin-top: 9px;
}

.handleBtn2 {
  background-color: #8592a3 ;
  color: #fff;
  border: 1px solid #8592a3 ;
  text-transform: capitalize;
  margin-top: 9px;
}
.handleBtn2:hover {
  background-color: #fff !important;
  color: #337ab7 !important;
  font-weight: bold;
}
.handleDeleteIcon {
  margin-top: -7px !important;
  margin-right: 3px;
}
.handleBtn:hover {
  background-color: #fff !important;
  color: #337ab7 !important;
  font-weight: bold;
}
.handleBtn1 {
  background-color: #337ab7 !important;
  color: #fff !important;
  border-radius: 20px;
}
.handleBtn1:hover {
  background-color: #fff !important;
  color: #337ab7 !important;
  font-weight: bold;
  border: 1px solid #337ab7;
}
.handleLoginImg {
  max-width: "550px";
  width: "100%";
  display: "inline-block";
  margin-left: "32px";
  margin-top: 70px;
}
