.btn-top,
.btn6 {
  padding: 5px 0px !important;
  font-size: 15px;
  font-weight: 600;
  background-color: grey;
  color: white;
  border-radius: 5px;
  /* border-radius: 20px; */
}
.btn6:hover {
  background-color: white;
  color: #ec681f;
}
.btn-top:hover {
  background-color: white;
  color: #ec681f;
}
/* #planName {
  color: #fff;
}
#planName:hover {
  color: #ec681f !important;
} */

#card-body {
  color: antiquewhite !important;
  background-image: -webkit-linear-gradient(90deg, #cc520f 0%, #ec681f 100%);
  padding: 3px;
  border-radius: 5px;
  padding: 11px !important;
  height: 170px;
  max-height: 200px;
  border-radius: 10px;
  overflow: hidden;
  animation-duration: 5s;
}
@keyframes example {
  0% {
    box-shadow: 45px 170px #cc520f inset;
  }
  /* 25% {
    box-shadow: 33px 129px #cc520f inset;
  }
  50% {
    box-shadow: 22px 85px #cc520f inset;
  }
  75% {
    box-shadow: 11px 42px #cc520f inset;
  } */
  100% {
    box-shadow: 0px 0px #cc520f inset;
  }
}
#card-body:hover {
  color: black !important;
  background-image: -webkit-linear-gradient(90deg, #fff 0%, #fff 100%);
  color: #135aa7 !important;
  border: 1px solid #135aa7 !important;
  box-shadow: 45px 0px darkorange;
  animation-name: example;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
#card-title {
  margin-bottom: 0px;
}
#col-md-2 {
  margin-top: 13px;
}
.card-text {
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
}
#handleActiveBtn1 {
  max-width: 120px;
  width: 100%;
  display: inline-block;
  background-color: #1abb9c !important;
  border-radius: 20px;
  color: #ffffff !important;
  border: 1px solid #39b54a;
  padding: 2px 0px;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  margin-top: 5px;
}
#handleActiveBtn1:hover {
  max-width: 120px;
  width: 100%;
  display: inline-block;
  background-color: #ffffff !important;
  border-radius: 20px;
  color: #39b54a !important;
  border: 1px solid #39b54a;
  padding: 2px 0px;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  margin-top: 5px;
}
#handletHead th {
  color: #333333 !important;
  font-weight: 900;
  text-transform: capitalize;
  font-family: auto;
  font-size: 13px;
}
