.handleLoginImg {
  max-width: 550px;
  width: 100%;
  display: inline-block;
  margin-left: 32px;
}
@media screen and (max-width: 767px) {
  .handleLoginImg {
    margin-left: 0%;
  }
}
